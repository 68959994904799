(function($) {

	// Get URL Parameter
	var gup = function( name, url ) {
		if (!url) url = location.href;
		name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
		var regexS = "[\\?&]"+name+"=([^&#]*)";
		var regex = new RegExp( regexS );
		var results = regex.exec( url );
		return results == null ? null : results[1];
	}

	function get_perfect_bounds (marker) {
		var bounds = new google.maps.LatLngBounds();
		bounds.extend(marker.getPosition());

		// Don't zoom in too far on only one marker
		if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
			var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01);
			var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01);
			bounds.extend(extendPoint1);
			bounds.extend(extendPoint2);
		}

		return bounds;
	}

	/*
	*  new_map
	*
	*  This function will render a Google Map onto the selected jQuery element
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	$el (jQuery element)
	*  @return	n/a
	*/

	function new_map( $el ) {

		// var
		var $markers = $el.find('.marker');

		// vars
		var args = {
			zoom: 16,
			center: new google.maps.LatLng(0, 0),
			mapTypeControl: false,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			streetViewControl: false,
			styles: [{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"color":"#e6e6e2"}]},{"featureType":"landscape.man_made","elementType":"geometry.fill","stylers":[{"visibility":"off"}]},{"featureType":"landscape.natural.landcover","elementType":"geometry.fill","stylers":[{"color":"#3f8f93"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#3f8f93"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#3f8f93"},{"weight":0.5}]},{"featureType":"road.highway","elementType":"labels.text","stylers":[{"visibility":"simplified"}]},{"featureType":"road.highway","elementType":"labels.text.fill","stylers":[{"visibility":"off"}]},{"featureType":"road.highway","elementType":"labels.text.stroke","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#99cfd8"},{"lightness":30}]}]
		};


		// create map
		map = new google.maps.Map( $el[0], args);


		// add a markers reference
		map.markers = [];


		// add markers
		$markers.each(function(){

			add_marker( $(this), map );

		});


		// Add a marker clusterer to manage the markers.
		markerCluster = new MarkerClusterer(map, map.markers, {
			imagePath: '/wp-content/themes/pinus/img/map/mc',
			ignoreHiddenMarkers: true
		});


		// Close infoBox on map-click
		google.maps.event.addListener(map, "click", function(event) {
			infoBox.close();
		});


		// Close infoBox on esc-key
		$(document).keyup(function(e){
			if(e.keyCode === 27) {
				infoBox.close();
			}
		});


		// center map
		center_map( map );


		// filters
		map_filters();


		// open a specific infoBox by query (initial_map_article_id is a global var defined in map-block.php)
		if (typeof initial_map_article_id !== 'undefined') {
			var home_page_initial_map_article_id = initial_map_article_id;
		}

		var initMarkerId = parseInt(gup('mid', window.location.href), 10) || home_page_initial_map_article_id;
		var initMarker = null;

		if (initMarkerId) {
			// Find marker by id
			initMarker = map.markers.filter(function( markerObj ) {
				return markerObj.markerId === initMarkerId;
			});

			if (initMarker.length) {
				var bounds = get_perfect_bounds(initMarker[0]);

				map.fitBounds(bounds);

				infoBox.setOptions(initMarker[0].data);
				infoBox.open(map, initMarker[0]);

				// Zoom out more on home-page
				if ($('body').hasClass('home')) {
					setTimeout(function() {
						map.setZoom(8);
					}, 50);
				}
			}
		}

		// return
		return map;
	}

	/*
	*  add_marker
	*
	*  This function will add a marker to the selected Google Map
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	$marker (jQuery element)
	*  @param	map (Google Map object)
	*  @return	n/a
	*/

	function add_marker( $marker, map ) {

		// var
		var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

		// marker types
		var markerType = $marker.attr('data-type');
		var markerID = parseInt($marker.attr('data-id'), 10);
		var iconBase = '/wp-content/themes/pinus/img/map/';
		var icons = {
			attraction: {
				icon: {
					url: iconBase + 'map-marker-attraction.svg',
					scaledSize: new google.maps.Size(32, 32)
				},
				close: iconBase + 'close-dark.svg'
			},
			cooperation: {
				icon: {
					url: iconBase + 'map-marker-cooperation.svg',
					scaledSize: new google.maps.Size(32, 32)
				},
				close: iconBase + 'close.svg'
			},
			environment: {
				icon: {
					url: iconBase + 'map-marker-environment.svg',
					scaledSize: new google.maps.Size(32, 32)
				},
				close: iconBase + 'close.svg'
			},
			knowledge: {
				icon: {
					url: iconBase + 'map-marker-knowledge.svg',
					scaledSize: new google.maps.Size(32, 32)
				},
				close: iconBase + 'close.svg'
			}
		};


		// create marker
		var markerWidth = '270px';
		var markerOffset = -135;

		if ($(window).width() >= 400) {
			markerWidth = '360px';
			markerOffset = -180;
		}

		if ($(window).width() >= 768) {
			markerWidth = '450px';
			markerOffset = -225;
		}

		var marker = new google.maps.Marker({
			position	: latlng,
			icon		: icons[markerType].icon,
			optimized: false,
			map			: map,
			markerId	: markerID,
			data		: {
				boxClass: 'c-map-box-wrap c-map-box-wrap--' + markerType,
				content: $marker.html(),
				disableAutoPan: false,
				maxWidth: 0,
				pixelOffset: new google.maps.Size(markerOffset, -44),
				zIndex: null,
				boxStyle: {
					width: markerWidth
				},
				closeBoxMargin: '0',
				closeBoxURL: icons[markerType].close,
				infoBoxClearance: new google.maps.Size(10, 10),
				alignBottom: true
			},
			filters: {
				attraction: markerType === 'attraction',
				cooperation: markerType === 'cooperation',
				environment: markerType === 'environment',
				knowledge: markerType === 'knowledge'
			}
		});

		// add to array
		map.markers.push( marker );

		google.maps.event.addListener(marker, "click", function (e) {
			infoBox.close();
			infoBox.setOptions(this.data)
			infoBox.open(map, this);
		});

	}

	/*
	*  center_map
	*
	*  This function will center the map, showing all markers attached to this map
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	map (Google Map object)
	*  @return	n/a
	*/

	function center_map( map ) {

		// vars
		var bounds = new google.maps.LatLngBounds();
		var markersVisible = 0;

		// loop through all markers and create bounds
		$.each( map.markers, function( i, marker ){
			if (marker.visible) {
				var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
				bounds.extend( latlng );
				markersVisible++;
			}
		});

		if (markersVisible === 0) {
			return;
		}

		// only 1 marker?
		if( map.markers.length == 1 )
		{
			// set center of map
			map.setCenter( bounds.getCenter() );
			map.setZoom( 16 );
		}
		else
		{
			// fit to bounds
			map.fitBounds( bounds );
		}

	}

	function map_filters() {

		var filters = {
				attraction: false,
				cooperation: false,
				environment: false,
				knowledge: false
			}

		var get_set_options = function() {
			ret_array = []
			for (option in filters) {
				if (filters[option]) {
					ret_array.push(option)
				}
			}
			return ret_array;
		}

		var map_filter = function(id_val) {
			if (filters[id_val])
				filters[id_val] = false
			else
				filters[id_val] = true
		}

		var filter_markers = function() {
			var set_filters = get_set_options();
			var keep;
			var i;
			var opt;

			// for each marker, check to see if all required options are set
			for (i = 0; i < map.markers.length; i++) {
				marker = map.markers[i];

				// start the filter check assuming the marker will be displayed
				// if any of the required features are missing, set 'keep' to false
				// to discard this marker
				keep = true

				if (set_filters.length) {
					keep = false

					for (opt = 0; opt < set_filters.length; opt++) {
						if (marker.filters[set_filters[opt]]) {
							keep = true;
						}
					}
				}

				marker.setVisible(keep)
			}
		}

		$('input[name=filter]').change(function (e) {

			infoBox.close();
			map_filter(this.id);
			filter_markers();
			markerCluster.repaint();
			center_map(map);
		});
	}

	/*
	*  document ready
	*
	*  This function will render each map when the document is ready (page has loaded)
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	5.0.0
	*
	*  @param	n/a
	*  @return	n/a
	*/
	// global var
	var map = null;
	var markerCluster = null;
	var infoBox = new InfoBox(); /* http://htmlpreview.github.io/?https://github.com/googlemaps/v3-utility-library/blob/master/infobox/docs/reference.html */

	$(document).ready(function(){

		$('.acf-map').each(function(){

			// create map
			map = new_map( $(this) );

		});

	});

	})(jQuery);
